
    import { Component, Vue, Emit } from 'vue-property-decorator'
    import SelectType from './../../../../components/selectType/index.vue'
    import SelectSubject from './../../../../components/selectSubject/index.vue'
    import { Caxios } from './../../../../utils/axios'
    let qs = require('qs')
    @Component({
        name: 'Entrance',
        components: { SelectType, SelectSubject }
    })
    export default class Entrance extends Vue {
        private inputFlag: number = 0
        private searchObj: any = {
            page: 1,
            limit: 10,
            type: '',
            subject: '',
            keywords: '',
            update: 1
        }
        private dataLists: Array<any> = []


        @Emit()
        private callback (): string {
            return 'Detail'
        }
        private selecttypeCb (obj: any): void {
            this.searchObj.type = obj.type
        }
        private selectsubjectCb (obj: any): void {
            this.searchObj.subject = obj.subject
        }
        private focusEvent (): void {
            this.inputFlag = 1
        }
        private blurEvent (): void {
            this.inputFlag = this.searchObj.keywords ? 2 : 0
        }
        private searchEvent (): void {
            this.classChange()
        }
        private setAllFlag (): void {
            let _this: any = this
            _this.$store.commit('personal/setAllFlag')
        }
        private async classChange () {
            let _this: any = this,
                _store: any = _this.$store.getters["personal/personalData"],
                _data: any = {
                    page: this.searchObj.page,
                    limit: this.searchObj.limit,
                    update: this.searchObj.update,
                    stdid: _store.id
                };
            if(this.searchObj.type) {
                _data.type = this.searchObj.type
            }
            if(this.searchObj.subject) {
                _data.subject = this.searchObj.subject
            }
            if(this.searchObj.keywords) {
                _data.keywords = this.searchObj.keywords
            }
            let _res: any = await Caxios.get({ url: `/api/${_store.id}/classchanges?${qs.stringify(_data)}` });
            if(_res && _res.records && _res.records.length > 0) {
                this.dataLists = _res.records
            } else {
                this.dataLists = []
            }
        }
        beforeMount() {
            this.classChange()
        }
    }
