
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import { getYearList } from './../../../utils/common'
    import { mapGetters, mapActions } from 'vuex'

    @Component({
        name: 'PersonalKscf',
        computed: {
            ...mapGetters('personalKscf', ['kscfList', 'kscfItems'])
        },
        methods: {
            ...mapActions('personalKscf', ['setKscfList', 'setKscfItem'])
        }
    })
    export default class PersonalKscf extends Vue {
        kscfList!: Array<any>
        kscfItems!: Array<any>
        setKscfList!: any
        setKscfItem!: any
        private navList: Array<string> = getYearList().filter(v => v != '不限')
        private activeIndex: number = 0
        private stableKscfList: Array<any> = []
        private curKscfList: Array<any> = []
        private curKscfItems: Array<any> = []

        private tempObj: any = {
            name: '',
            phone: ''
        }
        private listFlag: Array<boolean> = []
        @Watch('kscfList', { immediate: true, deep: true })
        kscfListWatch(newVal: Array<any>): void {
            if(newVal.length > 0) {
                this.stableKscfList = [ ...newVal ]
                this.setCurKscfList(new Date().getFullYear())
            }
        }
        @Watch('kscfItems', { immediate: true, deep: true })
        kscfItemsWatch(newVal: Array<any>): void {
            if(newVal.length > 0) {
                this.curKscfItems = [ ...newVal ]
            } else {
                this.curKscfItems = []
            }
        }
        
        private setCurKscfList (year: any): void {
            this.curKscfList = []
            this.stableKscfList.forEach((item: any, index: number): void => {
                if(item.create_time_str.indexOf(year) >= 0) {
                    this.curKscfList.push(item)
                }
            })
            this.listFlag = new Array(this.curKscfList.length).fill(false)
        }
        private listFlagEvent (item: any, index: number): void {
            let _this: any = this
            _this.listFlag.forEach((v: boolean, i: number): void => {
                if(i != index) {
                    _this.$set(_this.listFlag, i, false)
                } else {
                    _this.$set(_this.listFlag, i, !v)
                    if(!v) {
                        this.getTableData(item.exam_id)
                    }
                }
            })
        }
        private getTableData (id: number): void {
            this.setKscfItem({ ...this.tempObj, id: id })
        }
        private changeActiveIndex (item: string, index: number): void {
            this.activeIndex = index
            this.setCurKscfList(item)
        }
        beforeMount() {
            let _this: any = this,
                _store: any = _this.$store.getters['personal/personalData'];
            this.tempObj.name = _store.name || _store.stdname
            this.tempObj.phone = localStorage.getItem('fangtian_phone')
            this.setKscfList(this.tempObj)
        }
    }
