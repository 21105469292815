
    import { Component, Vue } from 'vue-property-decorator'
    import Entrance from './components/entrance.vue'
    import Detail from './components/detail.vue'
    @Component({
        name: 'PersonalZdzk',
        components: { Entrance, Detail }
    })
    export default class PersonalZdzk extends Vue {
        private componentName: string = 'Entrance'

        private callback (componentName: string): void {
            this.componentName = componentName
        }
    }
