
    import { Component, Vue, Watch, Emit, Prop } from 'vue-property-decorator'
    import { mapGetters, mapActions } from 'vuex'
    @Component({
        name: 'EditSchool',
        computed: {
            ...mapGetters('common', ['branchSchoolId']),
            ...mapGetters('personal', ['areaSchoolList', 'allFlag', 'attrEmptyFlag'])
        },
        methods: {
            ...mapActions('personal', ['areaSchool'])
        }
    })
    export default class EditSchool extends Vue {
        branchSchoolId!: number
        allFlag!: number
        attrEmptyFlag!: number
        areaSchool!: any
        areaSchoolList!: any
        @Prop() placeholder!: string
        private schoolName: string = ''
        private schoolcode: string = ''
        private status: number = 0
        private curAreaSchoolList: Array<any> = []
        private useAreaSchoolList: Array<any> = []
        private areaSchoolListFlag: boolean = false
        private activeIndex: number = -1

        @Watch('branchSchoolId', { immediate: true, deep: true })
        branchSchoolIdWatch(newVal: number) {
            if(newVal) {
                this.areaSchool(newVal)
            }
        }
        @Watch('areaSchoolList', { immediate: true, deep: true })
        areaSchoolListWatch(newVal: Array<any>): void {
            // console.log(newVal)
            this.curAreaSchoolList = newVal
            this.formatAreaSchoolList()
        }
        @Watch('allFlag', { immediate: true, deep: true })
        allFlagWatch(newVal: number): void {
            if(newVal != -1) {
                this.areaSchoolListFlag = false
            }
        }

        @Watch('attrEmptyFlag', { immediate: true, deep: true })
        attrEmptyFlagWatch(newVal: number): void {
            let _this: any = this
            if(_this.$route.path == '/personal/index') {
                this.setSchoolName()
            }
        }

        private formatAreaSchoolList (): void {
            this.useAreaSchoolList = []
            if(this.schoolName) {
                this.curAreaSchoolList.forEach((item: any, index: number): void => {
                    if(item.name.indexOf(this.schoolName) >= 0) {
                        let _item: any = { ...item },
                            _arr: Array<string> = item.name.split(this.schoolName);
                        console.log(item)
                        this.useAreaSchoolList.push(item)
                    }
                })
            } else {
                this.useAreaSchoolList = this.curAreaSchoolList
            }
        }

        private focusEvent (): void {
            this.areaSchoolListFlag = true
            this.selectEvent()
            this.editschoolCb(0)
        }
        private selectEvent () {
            let _schoolNameDom: any = this.$refs.schoolNameDom
            _schoolNameDom.select()
        }
        private clickEvent (): void {
            setTimeout(() => {
                this.selectEvent()
            }, 1)
        }
        private blurEvent (): void {
        }
        @Emit()
        private editschoolCb(status: number): any {
            return { school_name: this.schoolName, schoolcode: this.schoolcode, status: status }
        }
        private setActiveIndex (item: any, index: number): void {
            this.activeIndex = index
            this.schoolName = item.name
            this.schoolcode = item.code
            this.areaSchoolListFlag = false
            this.editschoolCb(1)
        }

        private setSchoolName (): void {
            let personalData: any = localStorage.getItem('fangtian_personal_Data')
            if(personalData) {
                personalData = JSON.parse(personalData)
                this.schoolName = personalData.school_name
                this.schoolcode = personalData.schoolcode
            }
        }
        beforeMount() {
            this.setSchoolName()
        }
    }
