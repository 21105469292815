
    import { Component, Vue, Emit, Watch } from 'vue-property-decorator'
    import { mapGetters } from 'vuex'

    @Component({
        name: 'SelectType',
        computed: mapGetters('personal', ['allFlag'])
    })
    export default class SelectType extends Vue {
        allFlag!: any

        private typeText: string = 'Please select'
        private type: string = ''
        private itemClickedFalg: boolean = false
        private mouseoverFlag: boolean = false
        private activeIndex: number = -1
        private listFlag: boolean = false
        private curTypeList: Array<any> = [
            { text: 'Please select', id: ''},
            { text: '调课', id: 'LESSON'},
            { text: '转班', id: 'CLASS'},
        ]

        @Watch('allFlag', { immediate: true, deep: true })
        allFlagWatch(newVal: number): void {
            if(newVal != -1) {
                this.listFlag = false
                this.mouseoverFlag = false
                this.selecttypeCb(1)
            }
        }

        private mouseoverEvent (): void {
            this.mouseoverFlag = true
            this.selecttypeCb(0)
        }
        @Emit()
        private selecttypeCb(status: number): any {
            return { type: this.type, status: status, text: this.typeText }
        }
        private listFlagEvent (): void {
            this.listFlag = !this.listFlag
        }
        private changeActiveEvent (item: any, index: number): void {
            this.typeText = item.text
            this.type = item.id
            this.itemClickedFalg = true
            this.mouseoverFlag = false
            this.listFlag = false
            this.selecttypeCb(1)
        }
    }
