
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
    import { mapGetters, mapActions } from 'vuex'
    import UsePwd from './../../../components/password/index.vue'
    import EditName from './../../../components/editName/index.vue'
    import EditCard from './../../../components/editCard/index.vue'
    import EditSchool from './../../../components/editSchool/index.vue'
    import EditGrade from './../../../components/editGrade/index.vue'
    import EditJob from './../../../components/editJob/index.vue'

    import { checkCard, checkPhone } from './../../../utils/validate'

    import axios from "axios"
    @Component({
        name: 'EditComp',
        components: { UsePwd, EditName, EditCard, EditSchool, EditGrade, EditJob },
        computed: {
            ...mapGetters('personal', ['personalData', 'updateFlag']),
            ...mapGetters('course', ['grade'])
        },
        methods: {
            ...mapActions('personal', ['editbase'])
        }
    })
    export default class EditComp extends Vue {
        personalData!: any
        updateFlag!: string
        editbase!: any
        grade!: any
        private editFlag: boolean = false
        private curPersonData: any = {}
        private hasSelected: boolean = false

        private operateData: any = {}

        private gradeList: Array<any> = []
        private setAllFlag (): void {
            let _this: any = this
            _this.$store.commit('personal/setAllFlag')
        }

        private nameMsg: string = ''
        private usenameCb (obj: any): void {
            let { phone, status } = obj
            this.operateData.name = phone
            if(phone) {
                this.nameMsg = ''
            } else {
                this.nameMsg = 'Name cannot be empty'
            }
            if(status == 0) {
                this.nameMsg = ''
            }
        }

        private passwordMsg: string = ''
        private usepwdCb (obj: any): void {
            let { password, status } = obj
            this.operateData.password = password
            
            if(password) {
                if(password.length < 8 || password.length > 12) {
                    this.passwordMsg = 'Wrong password format'
                // } else if(this.operateData.confirmpassword && this.operateData.confirmpassword != password) {
                //     this.passwordMsg = '两次密码输入不一致'
                } else {
                    this.passwordMsg = ''
                }
            } else {
                this.passwordMsg = 'Original password cannot be empty'
            }

            if(status == 0) {
                this.passwordMsg = ''
            }
        }

        private passwordMsg2: string = ''
        private usepwdCb2 (obj: any): void {
            let { password, status } = obj
            this.operateData.confirmpassword = password
            if(password) {
                if(password.length < 8 || password.length > 12) {
                    this.passwordMsg2 = 'Wrong password format'
                // } else if(this.operateData.password && this.operateData.password != password) {
                //     this.passwordMsg2 = '两次密码输入不一致'
                } else {
                    this.passwordMsg2 = ''
                }
            } else {
                this.passwordMsg2 = 'New password cannot be empty'
            }

            if(status == 0) {
                this.passwordMsg2 = ''
            }
        }
        
        private cardMsg: string = ''
        private EditcardCb (obj: any): void {
            let { identificationCard, status } = obj
            this.operateData.identification_card = identificationCard
            if(identificationCard) {
                if(!checkCard(identificationCard)) {
                    this.cardMsg = '身份证号格式错误'
                } else {
                    this.cardMsg = ''
                }
            } else {
                this.cardMsg = ''
            }
            if(status == 0) {
                this.cardMsg = ''
            }
        }

        private phoneMsg1: string = ''
        private phoneCb1 (obj: any): void {
            let { phone, status } = obj
            this.operateData.phone = phone
            if(checkPhone(phone)) {
                this.phoneMsg1 = ''
            } else {
                this.phoneMsg1 = '手机号格式不正确'
            }
            if(status == 0) {
                this.phoneMsg1 = ''
            }
        }
        private phoneMsg2: string = ''
        private phoneCb2 (obj: any): void {
            let { phone, status } = obj
            this.operateData.phone2 = phone
            if(phone) {
                if(checkPhone(phone)) {
                    this.phoneMsg2 = ''
                } else {
                    this.phoneMsg2 = '手机号格式不正确'
                }
            } else {
                this.phoneMsg2 = ''
            }
            if(status == 0) {
                this.phoneMsg2 = ''
            }
        }
        
        private editschoolMsg: string = ''
        private editschoolCb(obj: any): void {
            let { school_name, schoolcode, status } = obj
            this.operateData.school_name = school_name
            this.operateData.schoolcode = schoolcode
            if(school_name) {
                this.editschoolMsg = ''
            } else {
                this.editschoolMsg = '学校不能为空'
            }
            if(status == 0) {
                this.editschoolMsg = ''
            }
        }

        private editgradeMsg: string = ''
        private editgradeCb(obj: any): void {
            let { grade, status, text } = obj
            this.operateData.grade = grade
            this.operateData.grade_name = text
            if(grade) {
                this.editgradeMsg = ''
            } else {
                this.editgradeMsg = 'Grade cannot be empty'
            }
            if(status == 0) {
                this.editgradeMsg = ''
            }
        }

        private editjobMsg1: string = ''
        private editjobCb1 (obj: any): void {
            let { jobId, status, clickNum, text } = obj
            this.operateData.f_job = jobId
            this.operateData.father_job = text
            if(jobId || (clickNum == 0 && !jobId)) {
                this.editjobMsg1 = ''
            } else {
                this.editjobMsg1 = '父亲职业不能为空'
            }
            if(status == 0) {
                this.editjobMsg1 = ''
            }
        }

        private editjobMsg2: string = ''
        private editjobCb2 (obj: any): void {
            let { jobId, status, clickNum, text } = obj
            this.operateData.m_job = jobId
            this.operateData.mother_job = text
            if(jobId || (clickNum == 0 && !jobId)) {
                this.editjobMsg2 = ''
            } else {
                this.editjobMsg2 = '母亲职业不能为空'
            }
            if(status == 0) {
                this.editjobMsg2 = ''
            }
        }

        @Watch('personalData', { immediate: true, deep: true })
        personalDataWatch(newVal: any): void {
            console.log(newVal, '....')
            if(newVal.id) {
                this.curPersonData = { ...newVal }
                this.setOperateData(newVal)
            }

        }
        @Watch('grade', { immediate: true, deep: true })
        gradeWatch(newVal: any): void {
            this.gradeList = []
            if(newVal.length > 0) {
                newVal.forEach((item: any, index: number): void => {
                    if(item.text == '不限') {
                        item.text = '请选择年级'
                    }
                    this.gradeList.push(item)
                })
            }
        }
        @Watch('updateFlag', { immediate: true, deep: true })
        setUpdateFlagWatch(newVal: string): void {
            let _this: any = this
            if(newVal) {
                if(newVal == '信息修改成功') {
                    _this.$message.success('Information amended successfully')
                } else {
                    _this.$message.warning(newVal)
                }
            }
        }
        private setOperateData (newVal: any): void {
            Object.keys(newVal).forEach((attr: string): void => {
                if(attr != 'name' && attr != 'grade_name' && attr != 'stdname' && attr != 'gradename') {
                    this.operateData[attr] = newVal[attr]
                } else {
                    if(attr == 'name' || attr == 'stdname') {
                        this.operateData.name = newVal.name || newVal.stdname
                    }
                    if(attr == 'grade_name' || attr == 'gradename') {
                        this.operateData.grade_name = newVal.name || newVal.stdname
                    }
                }
            })
            console.log(this.operateData)
        }

        private openAndSelectFile ():void {
            let _this: any = this;
            _this.$refs.uploadFileDom.click()
        }
        private async fileChangeEvent(ev: any) {
            let file: any = ev.target.files[0],
                _this: any = this,
                formData = new FormData();
            formData.append("file", file);
            if (file) {
                let api: string = ''
                if (window.location.href.indexOf('1315') > 0) {
                    api = '/api/'
                } else {
                   api = process.env.VUE_APP_BASE_URL
                }
                axios.post(`${api}upload`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res: any) => {
                    if (res.data.code == "10000" || res.data.code == 10000) {
                        _this.$set(_this.curPersonData, 'icon', `${res.data.data.domain}${res.data.data.contextpath}`)
                    } else {
                        _this.$message.warning(res.data.msg);
                    }
                })
                .catch((err: any): void => {
                    console.log(err);
                });
            }
        }


        private editEvent () {
            let _this: any = this
            if(this.editFlag) {
                // 添加判断条件
                if(this.nameMsg) {
                    _this.$message.warning(this.nameMsg)
                    return
                }
                if(this.passwordMsg) {
                    _this.$message.warning(this.passwordMsg)
                    return
                }
                if(this.passwordMsg2) {
                    _this.$message.warning(this.passwordMsg2)
                    return
                }
                if(this.cardMsg) {
                    _this.$message.warning(this.cardMsg)
                    return
                }

                if(this.phoneMsg1) {
                    _this.$message.warning(this.phoneMsg1)
                    return
                }
                if(this.phoneMsg2) {
                    _this.$message.warning(this.phoneMsg2)
                    return
                }
                if(this.editschoolMsg) {
                    _this.$message.warning(this.editschoolMsg)
                    return
                }
                if(this.editgradeMsg) {
                    _this.$message.warning(this.editgradeMsg)
                    return
                }
                if(this.editjobMsg1) {
                    _this.$message.warning(this.editjobMsg1)
                    return
                }
                if(this.editjobMsg2) {
                    _this.$message.warning(this.editjobMsg2)
                    return
                }
                this.operateData.icon = this.curPersonData.icon
                this.operateData.sex = this.curPersonData.sex
                console.log(this.operateData)
                this.editbase(this.operateData)
                this.editFlag = !this.editFlag
            } else {
                for(let attr in this.operateData) {
                    this.operateData[attr] = ''
                }
                _this.$store.commit('personal/setAttrEmptyFlag')
                this.editFlag = !this.editFlag
                this.setOperateData(this.curPersonData)
            }
        }
        
        beforeDestroy() {
            let _this: any = this
            _this.$store.commit('personal/setUpdateFlag', '')
        }
    }
