
    import { Component, Vue, Emit, Watch, Prop } from 'vue-property-decorator'
    import { mapGetters } from 'vuex'

    @Component({
        name: 'EditJob',
        computed: {
            ...mapGetters('personal', ['allFlag', 'attrEmptyFlag'])
        }
    })
    export default class EditJob extends Vue {
        @Prop() placeholder!: string
        allFlag!: any
        attrEmptyFlag!: number
        private curJobList: Array<any> = [
            // { text: '请选择', id: '' },
            { text: 'Others', id: '1' },
            { text: 'Teacher', id: '2' },
            { text: 'Doctor', id: '3' },
            { text: 'Nurse', id: '4' }
        ]
        private curJobText: string = 'Please select'
        private curJobId: string = ''
        private activeIndex: number = -1
        private listFlag: boolean = false
        private mouseoverFlag: boolean = false
        private itemClickedFalg: boolean = false
        private clickNum: number = 0
        private menuJob: string = ''

        @Watch('allFlag', { immediate: true, deep: true })
        allFlagWatch(newVal: number): void {
            if(newVal != -1) {
                this.listFlag = false
                this.mouseoverFlag = false
                this.editjobCb(1)
            }
        }
        @Watch('attrEmptyFlag', { immediate: true, deep: true })
        attrEmptyFlagWatch(newVal: number): void {
            let _this: any = this
            if(_this.$route.path == '/personal/index') {
                this.setInitJob()
            }
        }

        private listFlagEvent (): void {
            this.listFlag = !this.listFlag
        }
        private mouseoverEvent (): void {
            this.mouseoverFlag = true
            this.editjobCb(0)
        }

        @Emit()
        private editjobCb(status: number): any {
            return { jobId: this.curJobId, status: status, clickNum: this.clickNum, text: this.curJobId == '1' ? this.menuJob : this.curJobText }
        }
        private blurEvent () {
            this.editjobCb(1)
        }
        private changeActiveEvent (item: any, index: number): void {
            this.curJobId = item.id
            this.curJobText = item.text
            this.activeIndex = index
            this.itemClickedFalg = true
            this.mouseoverFlag = false
            this.listFlag = false
            this.clickNum += 1
            if(item.id != '1') {
                this.editjobCb(1)
            } else {
                this.menuJob = ''
            }
        }
        private setInitJob (): void {
            let personalData: any = localStorage.getItem('fangtian_personal_Data')
            if(personalData) {
                personalData = JSON.parse(personalData)
                if(this.placeholder == "Entry of father's occupation information") {
                    this.curJobText = personalData.f_job == '1' ? '其他' : (personalData.father_job ? personalData.father_job : this.curJobText)
                    this.menuJob = personalData.father_job
                    this.activeIndex = personalData.f_job ? personalData.f_job : -1
                    this.curJobId = personalData.f_job
                } else {
                    this.curJobText = personalData.m_job == '1' ? '其他' : (personalData.mother_job ? personalData.mother_job : this.curJobText)
                    this.menuJob = personalData.mother_job
                    this.activeIndex = personalData.m_job ? personalData.m_job : -1
                    this.curJobId = personalData.m_job
                }
            }
        }
        beforeMount() {
            this.setInitJob()
        }
    }
