
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters, mapMutations, mapActions } from "vuex";

import { Caxios } from "./../../../utils/axios";
import axios from "axios";
let save_e = require("./../../../assets/imgs/save_e.png"),
  save_f = require("./../../../assets/imgs/save_f.png"),
  print_w = require("./../../../assets/imgs/print_w.png"),
  print_b = require("./../../../assets/imgs/print_b.png"),
  arraw_w = require("./../../../assets/imgs/arraw_w.png"),
  arraw_b = require("./../../../assets/imgs/arraw_b.png");
@Component({
  name: "PersonalTtl",
  computed: {
    ...mapGetters("personalTtl", ["practiceList"]),
  },
  methods: {
    ...mapActions('personal', ['getDailyWorksCount']),
    ...mapActions("personalTtl", ["setPracticeList"])
  },
})
export default class PersonalTtl extends Vue {
  setPracticeList!: any;
  practiceList!: Array<any>;
  getDailyWorksCount!: any
  private navList: Array<any> = ["Today's Daily Practice", "Past Daily Practice"];
  private activeIndex: number = 0;
  private saveEIcon: any = save_e;
  private saveFIcon: any = save_f;
  private printIcon: any = print_b;
  private arrawIcon: any = arraw_b;
  private printNum: number = 0;
  private middlePracticeItems: Array<any> = []; // 作为中转的天天练列表
  private curPracticeItems: Array<any> = []; // 天天练列表
  private emptyPracticeItem: any = {
    // 静态的练习题
    question: {
      analysis: "",
      analysis_web: "",
      answer: "",
      answer_web: "",
      attached: "",
      contents: "",
      contents_web: "",
      degree_name: "",
      grade_name: "",
      id: "",
      master_knowledge_name: "",
      option_list: "",
      options: "",
      options_map: "",
      options_web: "",
      optnum: "",
      pics: "",
      quest_from_name: "",
      quest_type: "",
      quest_type_name: "",
      remarks: "",
      score: "",
      slave_knowledge_names: "",
      slave_knowledges: "",
      subject: "",
      subject_name: "",
      year: "",
      mineOptionMap: [],
    },
    answer: {
      answer: null,
      answerresult: "",
      answerstate: "",
      answertime: "",
      attached: "",
      classes: null,
      classid: null,
      classname: "",
      collected: null,
      correctattached: "",
      correctnum: "",
      correctpics: null,
      courseid: null,
      id: "",
      name: null,
      notebookid: null,
      number: null,
      pics: [],
      question: null,
      questionid: "",
      remark: null,
      revisestate: null,
      score: "",
      size: null,
      starttime: null,
      stdid: "",
      stdname: "",
      testid: "",
      voiurl: null,
      subject: "",
      lessonnumber: "",
      totallesson: "",
      answerid: "",
      outerid: "",
    },
  };
  private hasAnswerFlag: Array<boolean> = []; // 是否包含解析标识
  private myAnswerListFlag: Array<boolean> = []; // 是否显示我的答案
  private viewAnswerListFlag: Array<boolean> = []; // 是否显示标准答案
  private printFlag: Array<boolean> = []; // 是否加入打印标识
  private printList: Array<any> = []; // 设置需要打印的题目
  private loadingFlag: boolean = true; // loading动画标识
  private canClickFlag: boolean = false; // 标识是否可以切换tab
  private selectOptionFlag: Array<any> = []; // 单选题是否选中
  private myanswerPic: Array<any> = []; // 我的答案，上传的图片
  private hasSelected: boolean = false

  private updateImgAllData: any = {}
  private tempObj: any = {
    pagetype: 1,
    stdid: "",
  };

  private selectList: Array<any> = []
  private selectItem: any = {}
  private timeList: Array<number> = []
  private timeActiveIndex: number = -1
  private hoverIndex: number = -1

  @Watch("practiceList", { immediate: true, deep: true })
  practiceListWatch(newVal: Array<any>, oldVal: any): void {
    
    this.selectList = []
    this.hasAnswerFlag = [];
    this.printFlag = [];
    this.curPracticeItems = [];
    this.middlePracticeItems = [];

    this.myAnswerListFlag = [];
    this.viewAnswerListFlag = [];
    this.selectOptionFlag = [];

    
    let testId: string = "";
    this.loadingFlag = false;
    if (Array.isArray(newVal) && newVal.length > 0) {
      let _tempArr: Array<any> = [];
      for (let i: any = 0, len: number = newVal.length; i < len; i++) {
        if (testId != newVal[i].id) {
          let _item: any = newVal[i];
          testId = _item.id;
          _tempArr.push(_item);
        }
        if(this.activeIndex == 1) {
          this.selectList.push(newVal[i])
          this.canClickFlag = false
        }
      }
      if(this.activeIndex == 0) {
        this.todaytestdetail(_tempArr);
      } else {
        this.selectItem = this.selectList[0]
        this.selectPraItem(this.selectList[0].id)
      }
    } else {
      this.canClickFlag = false;
    }

  }
  private async todaytestdetail(arr: Array<any>) {
    let _this: any = this,
      _tempArr: Array<any> = [];
    for (let i: any = 0, len: number = arr.length; i < len; i++) {
      let _item: any = arr[i];
      _item.idx = i;
      await this.todaytestdetailPromise(_item, arr.length);
    }
  }
  private todaytestdetailPromise(_item: any, len: number) {
    return new Promise((resolve: any, reject: any): any => {
      this.todaytestdetailAjax(_item, len);
      resolve();
    });
  }
  private async todaytestdetailAjax(_item: any, len: number) {
    this.middlePracticeItems[_item.idx] = null;
    let stdid: string = _item.stdid,
      testid: string = _item.id,
      question: any = await Caxios.get({
        url: `/api/${stdid}/${testid}/todaytestdetail?stdid=${stdid}&testid=${testid}`,
      });
    this.canClickFlag = false;
    this.middlePracticeItems[_item.idx] = [...question];

    if (this.middlePracticeItems.every((itm) => itm)) {
      let useArr: any = [];
      this.middlePracticeItems.forEach((itm: any, index: number): void => {
        if (itm) {
          itm.forEach((v: any, i: number): void => {
            useArr.push(v);
          });
        }
      });

      this.hasAnswerFlag = new Array(useArr.length).fill(false);
      this.printFlag = new Array(useArr.length).fill(false);
      this.myAnswerListFlag = new Array(useArr.length).fill(false);
      this.viewAnswerListFlag = new Array(useArr.length).fill(false);
      for (let i: number = 0, len = useArr.length; i < len; i++) {
        let _arr: Array<boolean> = [false, false, false, false, false];
        this.selectOptionFlag[i] = [..._arr];
      }
      this.getPracticeItem(useArr);
    }
  }
  private getPracticeItem(useArr: Array<any>) {
    let _this: any = this,
      promises = useArr.map((itm: any): any => {
        return {
          testid: itm.testid,
          ajaxRes: Caxios.get({
            url: `/api/questions?ids=${itm.questionid}`,
          }),
        };
      });
    Promise.all(promises)
      .then((posts: Array<any>): void => {
        if (Array.isArray(posts) && posts.length > 0) {
          posts.map((outer: any, outerIdx: number): void => {
            outer.ajaxRes.then((data: Array<any>): void => {
              if (Array.isArray(data) && data.length > 0) {
                let _temp: any = { ...this.emptyPracticeItem },
                  _index: number = -1;
                _temp.question = { ...data[0] };
                useArr.forEach((v: any, i: number): void => {
                  if (v.testid == outer.testid && v.questionid == data[0].id) {
                    _index = i;
                  }
                });

                _temp.answer = {
                  ..._temp.answer,
                  ..._this.practiceList[outerIdx],
                  ...{
                    collected: useArr[_index].collected,
                    answerstate: useArr[_index].answerstate,
                    notebookid: useArr[_index].notebookid,
                    pics: useArr[_index].pics,
                    score: useArr[_index].score,
                    answer: useArr[_index].answer,
                    testid: useArr[_index].testid,
                    outerid: useArr[_index].id,
                  },
                };

                if (_temp.question.options_map) {
                  _temp.question.mineOptionMap = [];
                  let _keys: Array<string> = Object.keys(
                    _temp.question.options_map
                  );
                  if (_keys.length >= 4) {
                    if (
                      _temp.answer &&
                      _temp.answer.answerstate == "CORRECTED"
                    ) {
                      _keys.forEach((attr: string, index: number): void => {
                        _temp.question.mineOptionMap[
                          index
                        ] = `<div class="flex ai-c personel-option-list-item ${
                          attr == _temp.answer.answer ? "error" : ""
                        } ${
                          attr == _temp.question.answer ? "success" : ""
                        }"><p class="tac label">${attr}</p><p class="cont">${
                          _temp.question.options_map[attr]
                        }</p></div>`;
                      });
                    } else if (
                      _temp.answer &&
                      _temp.answer.answerstate == "UNSUBMIT"
                    ) {
                      _keys.forEach((attr: string, index: number): void => {
                        _temp.question.mineOptionMap[
                          index
                        ] = `<div class="flex ai-c personel-option-list-item common"><p class="tac label">${attr}</p><p class="cont">${_temp.question.options_map[attr]}</p></div>`;
                      });
                    }
                  }
                }

                _this.$set(_this.curPracticeItems, outerIdx, _temp);
              }
            });
          });
          if (_this.loadingFlag) {
            _this.loadingFlag = false;
          }
        }
      })
      .catch((reason: any): void => {
        console.log(reason);
      });
  }
  private selectOptionEvent(index: number, idx: number, item: any): void {
    let _this: any = this;
    if (
      item.answer.answerstate == "UNSUBMIT" &&
      (item.question.quest_type == "1" || item.question.quest_type == 1)
    ) {
      let _arr: Array<boolean> = [..._this.selectOptionFlag[index]];
      _arr.forEach((v: boolean, i: number): void => {
        _arr[i] = false;
        if (i == idx) {
          _arr[i] = true;
        }
      });
      _this.$set(_this.selectOptionFlag, index, _arr);
    } else if (
      item.answer.answerstate == "UNSUBMIT" &&
      (item.question.quest_type == "2" || item.question.quest_type == 2)
    ) {
      let _arr: Array<boolean> = [..._this.selectOptionFlag[index]];
      if (_arr[idx]) {
        _arr[idx] = false;
      } else {
        _arr[idx] = true;
      }
      _this.$set(_this.selectOptionFlag, index, _arr);
    }
  }
  private async collectedEvent(index: number, item: any) {
    // 笔记收藏
    // 收藏、取消收藏
    let _this: any = this;
    if (item.answer.collected == "Y") {
      // 取消
      let _result: any = await Caxios.delete({
        url: `/api/note/cancel/${item.answer.notebookid}`,
      });
      if (_result) {
        _this.$set(_this.curPracticeItems[index].answer, "collected", "N");
        _this.$message.success("取消收藏成功");
      } else {
        _this.$message.warning("取消收藏失败");
      }
    } else {
      // 收藏
      let _data: any = {
          stdid: item.answer.stdid || this.tempObj.stdid,
          type: 2,
          questid: item.answer.questionid || item.question.id,
          courseid: item.answer.courseid,
          classid: item.answer.classid,
          subject: item.answer.subject,
          lessonnumber: item.answer.lessonnumber,
          totallesson: item.answer.totallesson,
          answerid: item.answer.outerid,
        },
        _result: any = await Caxios.post({
          url: `/api/note/collection`,
          data: _data,
        });
      if (_result.msg) {
        _this.$message.warning(_result.msg);
      } else {
        _this.$message.success("收藏成功");
        _this.$set(_this.curPracticeItems[index].answer, "collected", "Y");
        _this.$set(_this.curPracticeItems[index].answer, "notebookid", _result);
      }
    }
  }
  private hasAnswerFlagEvent(index: number, flag: boolean): void {
    // 打印的时候是否带上解析
    let _this: any = this;
    _this.$set(_this.hasAnswerFlag, index, flag);
  }
  private changeActiveIndex(index: number): void {
    // 笔记本、错题本切换
    if (!this.canClickFlag) {
      this.selectList = []
      this.canClickFlag = true;
      this.loadingFlag = true;
      this.activeIndex = index;
      this.tempObj.pagetype = index + 1;
      this.setPracticeList(this.tempObj);
    }
  }
  private myAnswerListFlagEvent(index: number): void {
    // 我的答案
    let _this: any = this;
    _this.myAnswerListFlag.forEach((v: boolean, i: number): void => {
      if (i != index) {
        _this.$set(_this.myAnswerListFlag, i, false);
      } else {
        _this.$set(_this.myAnswerListFlag, i, !v);
      }
    });
  }
  private viewAnswerListFlagEvent(index: number): void {
    // 查看答案
    let _this: any = this;
    _this.viewAnswerListFlag.forEach((v: boolean, i: number): void => {
      if (i != index) {
        _this.$set(_this.viewAnswerListFlag, i, false);
      } else {
        _this.$set(_this.viewAnswerListFlag, i, !v);
      }
    });
  }
  private printEvent(index: number, item: any): void {
    // 具体打印事件
    if (!this.printFlag[index]) {
      this.printNum += 1;
      this.formatPrintList(1, index, item);
    } else {
      this.printNum -= 1;
      this.formatPrintList(-1, index, item);
    }
    this.printIcon = this.printNum == 0 ? print_b : print_w;
    this.arrawIcon = this.printNum == 0 ? arraw_b : arraw_w;
    this.printFlag[index] = !this.printFlag[index];
  }
  private formatPrintList(type: number, index: number, item: any): void {
    /**
     * type 1的时候表示添加，-1的时候表示删除
     */
    switch (type) {
      case 1:
        this.printList[index] = item;
        break;
      case -1:
        this.printList[index] = null;
        break;
    }
  }

  private delMyanswerPic(index: number): void {
    let _this: any = this;
    _this.$set(this.myanswerPic, index, null);
  }
  private openAndSelectFile(index: number): void {
    let _this: any = this;
    _this.$refs[`uploadFileDom${index}`][0].click();
  }
  private async fileChangeEvent(ev: any, index: number) {
    let file: any = ev.target.files[0],
      _this: any = this,
      api: string = '',
      formData = new FormData();
    formData.append("file", file);

    if (window.location.href.indexOf('1315') > 0) {
        api = '/api/'
    } else {
        api = process.env.VUE_APP_BASE_URL
    }
    if (file) {
      axios
        .post(`${api}upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res: any) => {
          if (res.data.code == "10000" || res.data.code == 10000) {
            let imgSrc: string = `${res.data.data.domain}${res.data.data.contextpath}`;
            _this.$set(this.myanswerPic, index, imgSrc);
            this.updateImgAllData = { ...res.data.data}
          } else {
            _this.$message.warning(res.data.msg);
          }
        })
        .catch((err: any): void => {
          console.log(err);
        });
    }
  }

  private async selectPraItem (id: string) {
    this.selectList.forEach((item: any): void => {
      if(item.id == id) {
        this.selectItem = { ...item }
      }
    })
    let _res: any = await Caxios.get({ url: `/api/${this.selectItem.classid}/${this.selectItem.stdid}/tab?classid=${this.selectItem.classid}&stdid=${this.selectItem.stdid}` })
    if(_res && Array.isArray(_res) && _res.length > 0) {
      this.timeList = [ ..._res ]
      this.timeActiveIndex = _res.length - 1
      this.getPastPeriodTestDetail(_res[this.timeActiveIndex])
    }
  }
  private selectPraEvItem (ev: any) {
    let _value: string = ev.target.value;
    this.selectPraItem(_value)
  }
  private mouseEvent (index: number, type: number): void {
    if(type == 1) {
      this.hoverIndex = index + 1
    } else {
      this.hoverIndex = -1
    }
  }
  private clickEvent (item: number,index: number): any {
    this.timeActiveIndex = index
    this.hoverIndex = -1
    this.getPastPeriodTestDetail(item)
  }

  private async getPastPeriodTestDetail(index: number) {
    let _res: any = await Caxios.get({ url: `/api/${this.selectItem.stdid}/pastperiodtestdetail/${this.selectItem.classid}/${index}?classid=${this.selectItem.classid}&stdid=${this.selectItem.stdid}&lessonnumber=${index}` })
    this.hasAnswerFlag = [];
    this.printFlag = [];
    this.curPracticeItems = [];
    this.middlePracticeItems = [];

    this.myAnswerListFlag = [];
    this.viewAnswerListFlag = [];
    this.selectOptionFlag = [];
    let testId: string = "";
    this.loadingFlag = false;
    if(_res && Array.isArray(_res) && _res.length > 0) {
      let questids: string = '';
      _res.forEach((item: any): void => {
        questids += `${item.questionid},`
      })
      if (questids.length > 0) {
				questids = questids.substring(0, questids.length - 1)
			}
      this.getPastPeriodQuestDetail(_res, questids);
    } else {
      this.canClickFlag = false;
    }
  }

  private async getPastPeriodQuestDetail (arr: Array<any>, questids: string) {
    let _res: any = await Caxios.get({ url: `/api/questions?ids=${questids}` })
    if(_res && Array.isArray(_res) && _res.length > 0) {
      _res.forEach((item: any): void => {
        let _temp: any = {
          question: { ...item },
          answer: {}
        }
        arr.forEach((itm: any): void => {
          if(itm.questionid == item.id) {
            _temp.answer = { ...itm, ...this.selectItem }
          }
        })

        this.curPracticeItems.push(_temp)
      })


      this.hasAnswerFlag = new Array(this.curPracticeItems.length).fill(false);
      this.printFlag = new Array(this.curPracticeItems.length).fill(false);
      this.myAnswerListFlag = new Array(this.curPracticeItems.length).fill(false);
      this.viewAnswerListFlag = new Array(this.curPracticeItems.length).fill(false);
      for (let i: number = 0, len = this.curPracticeItems.length; i < len; i++) {
        let _arr: Array<boolean> = [false, false, false, false, false];
        this.selectOptionFlag[i] = [..._arr];
      }

    }

  }

  private async submitEvent(index: number, item: any) {
    if(item.answer.answerstate == 'UNSUBMIT') {
      let _this: any = this,
        _tempData: any = {
          id: item.answer.outerid,
          testid: item.answer.testid,
          questid: item.question.id,
          questtype: item.question.quest_type,
          answers: "",
          submitchannel: "99",
        };

      if (item.question.quest_type == "2" || item.question.quest_type == "1") {
        // 单、多选
        if (this.selectOptionFlag[index].includes(true)) {
          // 可以提交答案
          let _arr = ["A", "B", "C", "D", "E"];
          this.selectOptionFlag[index].forEach(
            (bol: boolean, idx: number): void => {
              if (bol) {
                _tempData.answers = `${_tempData.answers}${_arr[idx]}f&&f`;
              }
            }
          );
          if (_tempData.answers.length > 0) {
            _tempData.answers = _tempData.answers.substring(
              0,
              _tempData.answers.length - 4
            );
          }
          
        } else {
          _this.$message.warning("请选择答案");
          return
        }
      } else {
        if (this.myanswerPic[index]) {
          _tempData["answers"] = JSON.stringify([this.updateImgAllData])
        } else {
          _this.$message.warning("请选择图片");
          return
        }
      }

      let _res: any = await Caxios.post({
          url: "/api/todaytest/submit",
          data: [_tempData],
        });
        if(_res) {
          _this.$message.success('提交答案成功')
          this.setPracticeList(this.tempObj);
          this.getDailyWorksCount(this.tempObj.stdid)
        } else {
          _this.$message.warning(_res.msg)
        }
    }
  }

  private openWindowPrintEvent(): void {
    if (this.printNum > 0) {
      let _printList: Array<any> = [],
        _hasAnswerFlag: Array<any> = [];
      this.printList.forEach((item: any, index: number): void => {
        if (item) {
          _printList.push(item);
          _hasAnswerFlag.push(this.hasAnswerFlag[index]);
        }
      });
      localStorage.setItem(
        "fangtian_printList",
        JSON.stringify({
          printList: _printList,
          hasAnswerFlag: _hasAnswerFlag,
        })
      );
      setTimeout(() => {
        window.open("/print");
      }, 20);
    }
  }

  beforeMount () {

    let _this: any = this,
      _store: any = _this.$store.getters["personal/personalData"];
    this.tempObj.stdid = _store.id;
    this.setPracticeList(this.tempObj);
  }
}
