
  import { Component, Vue, Watch } from "vue-property-decorator"
  import { Caxios } from "./../../../utils/axios";
  @Component({
    props: {
      visible: {
        type: Boolean,
        default: false
      }
    }
  })
  export default class HkHowpay extends Vue {
    @Watch('visible')
    visibleChange (newVal: any, oldVal: any) {
        if (newVal) {
          this.getPayCode()
        }
    }
    private handleOk(): void {
      this.$emit('registSuc')
    }
    private cancel(): void {
      this.$emit('cancel')
    }
    public list: any = []
    public copyEvent (id: string): void {
      const html = document.getElementById(id)?.innerHTML
      const transfer = document.createElement('input')
      document.body.appendChild(transfer)
      transfer.value = html as string // 这里表示想要复制的内容
      transfer.focus();
      transfer.select();
      if (document.execCommand('copy')) {
          document.execCommand('copy')
      }
      transfer.blur()
      document.body.removeChild(transfer)
      this.$message.success('复制成功！')
  
    }
    async getPayCode () {
    const res: any = await Caxios.get({ url: '/api/listPayCode?branchSchoolId=' + localStorage.getItem('fangtian_branchSchoolId') })
    if (res.code !== '10000' || !res.data || res.data?.length <= 0) return
    //  const codeitem = res.data.find((item: any) => item.itemcode === 'PAY_CODE')
    //  const imgitem = res.data.find((item: any) => item.itemcode === 'PAY_IMG_URL')
    //  this.codeValue = codeitem.itemValue
    //  this.imgsrc = imgitem.itemValue
    this.list = res.data.map((item: any) => {
        return { label: item.itemcode, value: item.itemValue, remark: item.remark }
    })
  }
  }
  