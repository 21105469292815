
    import { Component, Vue, Emit, Watch } from 'vue-property-decorator'
    import { mapGetters } from 'vuex'

    @Component({
        name: 'SelectSubject',
        computed: mapGetters('personal', ['allFlag'])
    })
    export default class SelectSubject extends Vue {
        allFlag!: any

        private subjectText: string = 'Please select'
        private subject: string = ''
        private itemClickedFalg: boolean = false
        private mouseoverFlag: boolean = false
        private activeIndex: number = -1
        private listFlag: boolean = false
        private curSubjectList: Array<any> = [
            { text: 'Please select', id: ''},
            { text: 'Math', id: '1'},
            { text: 'Chinese', id: '3'},
            // { text: '化学', id: '4'},
            // { text: '英语', id: '6'},
        ]

        @Watch('allFlag', { immediate: true, deep: true })
        allFlagWatch(newVal: number): void {
            if(newVal != -1) {
                this.listFlag = false
                this.mouseoverFlag = false
                this.selectsubjectCb(1)
            }
        }


        private mouseoverEvent (): void {
            this.mouseoverFlag = true
            this.selectsubjectCb(0)
        }
        @Emit()
        private selectsubjectCb(status: number): any {
            return { subject: this.subject, status: status, text: this.subjectText }
        }
        private listFlagEvent (): void {
            this.listFlag = !this.listFlag
        }
        private changeActiveEvent (item: any, index: number): void {
            this.subjectText = item.text
            this.subject = item.id
            this.itemClickedFalg = true
            this.mouseoverFlag = false
            this.listFlag = false
            this.selectsubjectCb(1)
        }
    }
