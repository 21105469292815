var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"person-sc"},[_c('ul',{staticClass:"pos-r flex ai-c person-common-ul",class:{
      first: _vm.activeIndex == 0,
      second: _vm.activeIndex == 1,
    }},_vm._l((_vm.navList),function(item,index){return _c('li',{key:index,staticClass:"tac cur",class:{ active: _vm.activeIndex == index },on:{"click":function($event){return _vm.changeActiveIndex(index)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0),(_vm.curCollectionItems.length > 0 && !_vm.loadingFlag)?_c('ul',{staticClass:"note-error",class:{ note: _vm.activeIndex == 0, error: _vm.activeIndex == 1 }},_vm._l((_vm.curCollectionItems),function(item,index){return _c('li',{key:index,staticClass:"pos-r item"},[_c('div',{staticClass:"flex ai-c jc-s pos-a header"},[_c('div',{staticClass:"flex ai-c"},[(item && item.question)?_c('span',{staticClass:"subject"},[_vm._v(_vm._s(item.question.subject_name || "-"))]):_vm._e(),(item && item.question && item.question.score)?_c('span',{staticClass:"tac score"},[_vm._v(_vm._s(item.question.score)+"分")]):_vm._e()]),_c('div',{staticClass:"flex ai-c operate-btns"},[_c('span',{staticClass:"flex ai-c cur save",on:{"click":function($event){$event.stopPropagation();return _vm.collectedEvent(index, item)}}},[_c('img',{attrs:{"src":item && item.answer && item.answer.collected == 'N'
                  ? _vm.saveEIcon
                  : _vm.saveFIcon,"alt":""}}),_vm._v(" "+_vm._s(item && item.answer && item.answer.collected == "N" ? "收藏" : "已收藏")+" ")]),_c('span',{staticClass:"flex ai-c cur has-answer",on:{"click":function($event){return _vm.hasAnswerFlagEvent(index, true)}}},[_c('i',{staticClass:"dib pos-r",class:{ active: _vm.hasAnswerFlag[index] }}),_vm._v("包含解析 ")]),_c('span',{staticClass:"flex ai-c cur no-answer",on:{"click":function($event){return _vm.hasAnswerFlagEvent(index, false)}}},[_c('i',{staticClass:"dib pos-r",class:{ active: !_vm.hasAnswerFlag[index] }}),_vm._v("不包含解析 ")]),_c('span',{staticClass:"tac cur print",on:{"click":function($event){return _vm.printEvent(index, item)}}},[_vm._v(_vm._s(_vm.printFlag[index] ? "取消打印" : "Add to print"))])])]),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"topic-cont"},[(
              item &&
              item.question &&
              item.question.contents_web.indexOf('<img') >= 0
            )?_c('div',{staticClass:"img-wrapper",domProps:{"innerHTML":_vm._s(item.question.contents_web)}}):_vm._e(),(
              item &&
              item.question &&
              item.question.contents_web.indexOf('<img') < 0
            )?_c('div',{staticClass:"text-wrapper",domProps:{"innerHTML":_vm._s(item.question.contents_web)}}):_vm._e(),(item && item.question && item.question.mineOptionMap)?_c('ol',{staticClass:"option-list"},_vm._l((item.question.mineOptionMap),function(itm,idx){return _c('li',{key:idx,domProps:{"innerHTML":_vm._s(itm)}})}),0):_vm._e()]),(item && item.answer && item.answer.answerstate != 'UNSUBMIT' && item.answer.answerstate != '')?_c('div',{staticClass:"flex ai-c jc-s common-answer-title mb14px"},[_c('img',{attrs:{"src":require("@/assets/imgs/my_answer.png"),"alt":""}}),_c('p',{staticClass:"title"},[_vm._v("我的答案")]),_c('p',{staticClass:"flex_1 thin-width"}),_c('p',{staticClass:"flex ai-c close-arraw cur",on:{"click":function($event){return _vm.myAnswerListFlagEvent(index)}}},[_vm._v(" "+_vm._s(_vm.myAnswerListFlag[index] ? "收起" : "展开")),_c('span',{staticClass:"iconfont icon-arrow-down",class:{ rotate: _vm.myAnswerListFlag[index] }})])]):_vm._e(),_c('transition',{attrs:{"name":"waterfall","mode":"out-in"}},[(_vm.myAnswerListFlag[index])?_c('div',{staticClass:"topic-cont"},[(
                item &&
                item.answer &&
                item.answer.pics &&
                item.answer.pics.length > 0 &&
                !item.answer.answer
              )?_c('div',[_c('div',{staticClass:"img-wrapper"},[_c('img',{staticClass:"w100",attrs:{"src":item.answer.pics[0],"alt":""}})])]):_vm._e(),(
                item &&
                item.answer &&
                (!item.answer.pics ||
                  (item.answer.pics && item.answer.pics.length == 0)) &&
                item.answer.answer
              )?_c('div',{staticClass:"text-wrapper",domProps:{"innerHTML":_vm._s(item.answer.answer)}}):_vm._e(),(
                item &&
                item.answer &&
                (!item.answer.pics ||
                  (item.answer.pics && item.answer.pics.length == 0)) &&
                !item.answer.answer
              )?_c('div',{staticClass:"text-wrapper"},[_vm._v(" No data available… ")]):_vm._e()]):_vm._e()]),(item && item.answer && item.answer.answerstate != 'UNSUBMIT' && item.answer.answerstate != '')?_c('div',{staticClass:"flex ai-c jc-s common-answer-title mb14px"},[_c('img',{attrs:{"src":require("@/assets/imgs/answer.png"),"alt":""}}),_c('p',{staticClass:"title"},[_vm._v("查看答案")]),_c('p',{staticClass:"flex_1 thin-width"}),_c('p',{staticClass:"flex ai-c close-arraw cur",on:{"click":function($event){return _vm.viewAnswerListFlagEvent(index)}}},[_vm._v(" "+_vm._s(_vm.viewAnswerListFlag[index] ? "收起" : "展开")),_c('span',{staticClass:"iconfont icon-arrow-down",class:{ rotate: _vm.viewAnswerListFlag[index] }})])]):_vm._e(),_c('transition',{attrs:{"name":"waterfall","mode":"out-in"}},[(_vm.viewAnswerListFlag[index])?_c('div',{staticClass:"topic-cont mb0px"},[(
                item && item.question && item.question.analysis_web.length > 0
              )?_c('div',[(
                  item &&
                  item.question &&
                  item.question.analysis_web.indexOf('<img') >= 0
                )?_c('div',{staticClass:"img-wrapper",domProps:{"innerHTML":_vm._s(item.question && item.question.analysis_web)}}):_vm._e(),(
                  item &&
                  item.question &&
                  item.question.analysis_web.indexOf('<img') < 0
                )?_c('div',{staticClass:"text-wrapper",domProps:{"innerHTML":_vm._s(item.question.analysis_web)}}):_vm._e()]):_vm._e(),(item && item.question && !item.question.analysis_web)?_c('div',{staticClass:"text-wrapper"},[_vm._v(" No data available… ")]):_vm._e()]):_vm._e()])],1)])}),0):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingFlag),expression:"loadingFlag"}],staticClass:"loading"},[_c('img',{attrs:{"src":require("@/assets/imgs/loading.gif"),"alt":""}})]),(_vm.curCollectionItems.length == 0 && !_vm.loadingFlag)?_c('div',{staticClass:"tac pk-no-wrapper"},[_c('img',{staticClass:"dib",attrs:{"src":require("@/assets/imgs/empty.png"),"alt":""}}),_c('p',{staticClass:"tac"},[_vm._v("No data available")])]):_vm._e(),_c('div',{staticClass:"pos-f cur tac print-wrapper",class:{ 'num-up': _vm.printNum > 0 },on:{"click":_vm.openWindowPrintEvent}},[_c('img',{staticClass:"icon",attrs:{"src":_vm.printIcon,"alt":""}}),_c('p',{staticClass:"p-num"},[_vm._v(_vm._s(_vm.printNum)+" questions in total")]),_c('p',{staticClass:"ver"},[_vm._v("Add to print")]),_c('img',{staticClass:"arraw",attrs:{"src":_vm.arrawIcon,"alt":""}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }