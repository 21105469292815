
    import { Component, Vue, Emit, Watch } from 'vue-property-decorator'
    import { mapGetters } from 'vuex'

    @Component({
        name: 'EditGrade',
        computed: {
            ...mapGetters('course', ['grade']),
            ...mapGetters('personal', ['allFlag', 'attrEmptyFlag'])
        }
    })
    export default class EditGrade extends Vue {
        grade!: any
        allFlag!: any
        attrEmptyFlag!: number
        
        private curGradeList: Array<any> = []
        private curGradeText: string = ''
        private curGradeId: string = ''
        private activeIndex: number = -1
        private listFlag: boolean = false
        private mouseoverFlag: boolean = false
        private itemClickedFalg: boolean = false
        @Watch('grade', { immediate: true, deep: true })
        gradeWatch(newVal: any): void {
            this.curGradeList = []
            if(newVal.length > 0) {
                let _temp: Array<any> = [ ...newVal ]
                _temp.forEach((item: any, index: number): void => {
                    if(item.text == '请选择年级' || item.text == '全部年级') {
                        item.text = '请选择'
                    }
                    this.curGradeList.push(item)
                })
                this.curGradeList = this.curGradeList.filter((item: any): boolean => {
                    return item.text != '请选择'
                })
            }
        }

        @Watch('allFlag', { immediate: true, deep: true })
        allFlagWatch(newVal: number): void {
            if(newVal != -1) {
                this.listFlag = false
                this.mouseoverFlag = false
                this.editgradeCb(1)
            }
        }

        @Watch('attrEmptyFlag', { immediate: true, deep: true })
        attrEmptyFlagWatch(newVal: number): void {
            let _this: any = this
            if(_this.$route.path == '/personal/index') {
                this.formatCur()
            }
        }

        private listFlagEvent (): void {
            this.listFlag = !this.listFlag
        }
        private mouseoverEvent (): void {
            this.mouseoverFlag = true
            this.editgradeCb(0)
        }

        @Emit()
        private editgradeCb(status: number): any {
            return { grade: this.curGradeId, status: status, text: this.curGradeText }
        }
        private changeActiveEvent (item: any, index: number): void {
            this.curGradeId = item.id
            this.curGradeText = item.text
            this.activeIndex = index
            this.itemClickedFalg = true
            this.mouseoverFlag = false
            this.listFlag = false
            this.editgradeCb(1)
        }
        private formatCur (): void {
            let personalData: any = localStorage.getItem('fangtian_personal_Data')
            if(personalData) {
                personalData = JSON.parse(personalData)
                this.curGradeText = personalData.grade_name || personalData.gradename
                this.curGradeId = personalData.grade
                this.curGradeList.forEach((item: any, index: number): void => {
                    if(item.text == this.curGradeText) {
                        this.activeIndex = index
                    }
                })
            }
        }

        beforeMount() {
            this.formatCur()
        }
    }
