
import { Component, Vue, Watch } from "vue-property-decorator"

@Component({
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  }
})
export default class SModal extends Vue {
  public codeValue: any = ''
  public imgsrc: any = ''
  public name = JSON.parse(localStorage.getItem('fangtian_personal_Data') as string).name
  public handleOk(): void {
    this.$emit('registSuc')
  }
  private cancel(): void {
    this.$emit('cancel')
  }
}
