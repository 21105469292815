import { render, staticRenderFns } from "./hkhowpay.vue?vue&type=template&id=74bec796&scoped=true&"
import script from "./hkhowpay.vue?vue&type=script&lang=ts&"
export * from "./hkhowpay.vue?vue&type=script&lang=ts&"
import style0 from "./hkhowpay.vue?vue&type=style&index=0&id=74bec796&prod&lang=css&"
import style1 from "./hkhowpay.vue?vue&type=style&index=1&id=74bec796&prod&scoped=true&lang=stylus&rel=stylesheet%2Fstylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74bec796",
  null
  
)

export default component.exports